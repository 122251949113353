'use client';

import {
  Menu,
  Group,
  Container,
  UnstyledButton,
  Image,
  Title,
  Stack,
  Text,
  Burger,
} from '@mantine/core';
import classes from './Header.module.css';
import { Link } from '@/navigation';
// import { useCategoriesControllerFindAllQuery } from '../../redux/slices/helmutmuellerAPI';
import { ColorSchemeToggle } from '../ColorSchemeToggle/ColorSchemeToggle';
import { useDisclosure } from '@mantine/hooks';

type TLinks = (
  | {
      link: string;
      label: string;
      links: {
        link: string;
        label: string;
      }[];
    }
  | {
      link: string;
      label: string;
      links?: undefined;
    }
)[];

export function HeaderResponsive({
  logo,
  links,
}: {
  logo: { url: string; alt: string; height: number };
  links?: TLinks;
}) {
  // const { data, isSuccess } = useCategoriesControllerFindAllQuery(undefined, {});
  const [opened, { toggle }] = useDisclosure();

  let linkz: TLinks = links ?? [];

  // if (isSuccess && !links) {
  if (!links) {
    // linkz = data.map((link) => ({
    //   link: '/' + link.name.trim().toLowerCase().replaceAll(' ', '-'),
    //   label: link.name,
    // }));
    linkz = [
      ...linkz,
      // { link: '/umfragen/strassennamen', label: 'Aktuelle Umfrage' },
      { link: '/politik', label: 'Politik' },
      { link: '/pastoraler-raum', label: 'Pastoraler Raum' },
      // { link: '/impressum', label: 'Impressum' },
      { link: '/ueber-mich', label: 'Über mich' },
      { link: '/kontakt', label: 'Kontakt' },
    ];
  }

  const items = linkz.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link} component={Link} href={item.link} className={classes.link}>
        {item.label}
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu width={200} shadow="md">
          <Menu.Target>
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          </Menu.Target>

          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <UnstyledButton
        component={Link}
        key={link.label}
        href={link.link}
        className={classes.link}
        // onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </UnstyledButton>
    );
  });

  return (
    <header className={classes.header}>
      <Container size="md" fluid h={'100%'}>
        <div className={classes.inner}>
          <Group gap={10} wrap="nowrap">
            <Link href="/">
              <Image
                alt={logo.alt}
                src={logo.url}
                style={{ '--logo-height': logo.height > 60 ? `${logo.height}px` : '60px' }}
                className={classes.logo}
              />
            </Link>
            <Stack align="center" h={'100%'} gap={0} style={{ overflow: 'auto' }}>
              <Title order={2}>Helmut Müller</Title>
              <Text size="md">Burgbrohl</Text>
            </Stack>
          </Group>

          <Group visibleFrom="sm">
            <Group gap={5}>{items}</Group>
            <ColorSchemeToggle />
          </Group>
        </div>
      </Container>
    </header>
  );
}
