'use client';

import { Anchor, List } from '@mantine/core';
// import DDD from '../../../public/M.svg';

import classes from './Footer.module.css';
import { Link } from '../../navigation';
import { useTranslations } from 'next-intl';
// import { useCategoriesControllerFindAllQuery } from '../../redux/slices/helmutmuellerAPI';

interface FooterCenteredProps {
  links?: { link: string; label: string }[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function FooterCentered({ links }: FooterCenteredProps) {
  // const { data, isSuccess } = useCategoriesControllerFindAllQuery();
  const f = useTranslations('Footer');

  const nav = [
    // isSuccess &&  data && [
    // ...data.map((link, index) => (
    //   <List.Item key={index}>
    //     <Anchor
    //       key={index}
    //       component={Link}
    //       c="dimmed"
    //       href={'/' + link.name.trim().toLowerCase().replaceAll(' ', '-')}
    //       style={{ lineHeight: 1 }}
    //       size="sm"
    //     >
    //       {link.name}
    //     </Anchor>
    //   </List.Item>
    // )),

    <List.Item key={'politik'}>
      <Anchor
        key={'politik'}
        component={Link}
        c="dimmed"
        href={'/' + 'politik'}
        style={{ lineHeight: 1 }}
        size="sm"
      >
        Politik
      </Anchor>
    </List.Item>,
    <List.Item key={'pastoraler-raum'}>
      <Anchor
        key={'pastoraler-raum'}
        component={Link}
        c="dimmed"
        href={'/' + 'pastoraler-raum'}
        style={{ lineHeight: 1 }}
        size="sm"
      >
        Pastoraler Raum
      </Anchor>
    </List.Item>,
    <List.Item key={'kontakt'}>
      <Anchor
        key={'kontakt'}
        component={Link}
        c="dimmed"
        href={'/' + 'kontakt'}
        style={{ lineHeight: 1 }}
        size="sm"
      >
        Kontakt
      </Anchor>
    </List.Item>,
  ];

  const lin = links
    ? links
    : [
        { link: f('links.datenschutz.link'), label: f('links.datenschutz.label') },
        { link: f('links.impressum.link'), label: f('links.impressum.label') },
      ];

  const items = lin.map((link, index) => (
    <List.Item key={index}>
      <Anchor
        component={Link}
        c="dimmed"
        key={index}
        href={link.link}
        style={{ lineHeight: 1 }}
        size="sm"
      >
        {link.label}
      </Anchor>
    </List.Item>
  ));

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
        <List listStyleType="none" className={classes.links} pb={10}>
          {nav}
        </List>
        {/* <List listStyleType="none" pb={10}>
          <List.Item key={'umfragen'}>
            <Anchor
              key={'umfragen'}
              component={Link}
              c="dimmed"
              href={'/' + 'umfragen/strassennamen'}
              style={{ lineHeight: 1 }}
              size="sm"
            >
              Aktuelle Umfragen
            </Anchor>
          </List.Item>
        </List> */}
        <List listStyleType="none">{items}</List>

        {/* <Text component="p" size="xs" className={classes.statement} c={'dimmed'}>
          Design und Umsetzung by Martin Müller
        </Text> */}
      </div>
    </div>
  );
}
